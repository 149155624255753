/*eslint-disable */
// es6修复
require('core-js/modules/es6.symbol');
require('core-js/modules/es6.object.create');
require('core-js/modules/es6.object.define-property');
require('core-js/modules/es6.object.define-properties');
require('core-js/modules/es6.object.get-own-property-descriptor');
require('core-js/modules/es6.object.get-prototype-of');
require('core-js/modules/es6.object.keys');
require('core-js/modules/es6.object.get-own-property-names');
require('core-js/modules/es6.object.freeze');
require('core-js/modules/es6.object.seal');
require('core-js/modules/es6.object.prevent-extensions');
require('core-js/modules/es6.object.is-frozen');
require('core-js/modules/es6.object.is-sealed');
require('core-js/modules/es6.object.is-extensible');
require('core-js/modules/es6.object.assign');
require('core-js/modules/es6.object.is');
require('core-js/modules/es6.object.set-prototype-of');
require('core-js/modules/es6.object.to-string');
require('core-js/modules/es6.function.bind');
require('core-js/modules/es6.function.name');
require('core-js/modules/es6.function.has-instance');
require('core-js/modules/es6.parse-int');
require('core-js/modules/es6.parse-float');
require('core-js/modules/es6.number.constructor');
require('core-js/modules/es6.number.to-fixed');
require('core-js/modules/es6.number.to-precision');
require('core-js/modules/es6.number.epsilon');
require('core-js/modules/es6.number.is-finite');
require('core-js/modules/es6.number.is-integer');
require('core-js/modules/es6.number.is-nan');
require('core-js/modules/es6.number.is-safe-integer');
require('core-js/modules/es6.number.max-safe-integer');
require('core-js/modules/es6.number.min-safe-integer');
require('core-js/modules/es6.number.parse-float');
require('core-js/modules/es6.number.parse-int');
// require('core-js/modules/es6.math.acosh');
// require('core-js/modules/es6.math.asinh');
// require('core-js/modules/es6.math.atanh');
// require('core-js/modules/es6.math.cbrt');
// require('core-js/modules/es6.math.clz32');
// require('core-js/modules/es6.math.cosh');
// require('core-js/modules/es6.math.expm1');
// require('core-js/modules/es6.math.fround');
// require('core-js/modules/es6.math.hypot');
// require('core-js/modules/es6.math.imul');
// require('core-js/modules/es6.math.log10');
// require('core-js/modules/es6.math.log1p');
// require('core-js/modules/es6.math.log2');
// require('core-js/modules/es6.math.sign');
// require('core-js/modules/es6.math.sinh');
// require('core-js/modules/es6.math.tanh');
// require('core-js/modules/es6.math.trunc');
require('core-js/modules/es6.string.from-code-point');
require('core-js/modules/es6.string.raw');
require('core-js/modules/es6.string.trim');
require('core-js/modules/es6.string.iterator');
require('core-js/modules/es6.string.code-point-at');
require('core-js/modules/es6.string.ends-with');
require('core-js/modules/es6.string.includes');
require('core-js/modules/es6.string.repeat');
require('core-js/modules/es6.string.starts-with');
require('core-js/modules/es6.string.anchor');
require('core-js/modules/es6.string.big');
require('core-js/modules/es6.string.blink');
require('core-js/modules/es6.string.bold');
require('core-js/modules/es6.string.fixed');
require('core-js/modules/es6.string.fontcolor');
require('core-js/modules/es6.string.fontsize');
require('core-js/modules/es6.string.italics');
require('core-js/modules/es6.string.link');
require('core-js/modules/es6.string.small');
require('core-js/modules/es6.string.strike');
require('core-js/modules/es6.string.sub');
require('core-js/modules/es6.string.sup');
require('core-js/modules/es6.date.now');
require('core-js/modules/es6.date.to-json');
require('core-js/modules/es6.date.to-iso-string');
require('core-js/modules/es6.date.to-string');
require('core-js/modules/es6.date.to-primitive');
require('core-js/modules/es6.array.is-array');
require('core-js/modules/es6.array.from');
require('core-js/modules/es6.array.of');
require('core-js/modules/es6.array.join');
require('core-js/modules/es6.array.slice');
require('core-js/modules/es6.array.sort');
require('core-js/modules/es6.array.for-each');
require('core-js/modules/es6.array.map');
require('core-js/modules/es6.array.filter');
// es5已经支持
require('core-js/modules/es6.array.some');
require('core-js/modules/es6.array.every');
require('core-js/modules/es6.array.reduce');
require('core-js/modules/es6.array.reduce-right');

require('core-js/modules/es6.array.index-of');
require('core-js/modules/es6.array.last-index-of');
require('core-js/modules/es6.array.copy-within');
require('core-js/modules/es6.array.fill');
require('core-js/modules/es6.array.find');
require('core-js/modules/es6.array.find-index');
require('core-js/modules/es6.array.species');
require('core-js/modules/es6.array.iterator');
require('core-js/modules/es6.regexp.constructor');
require('core-js/modules/es6.regexp.to-string');
require('core-js/modules/es6.regexp.flags');
require('core-js/modules/es6.regexp.match');
require('core-js/modules/es6.regexp.replace');
require('core-js/modules/es6.regexp.search');
require('core-js/modules/es6.regexp.split');
require('core-js/modules/es6.promise');
// require('core-js/modules/es6.map');
require('core-js/modules/es6.set');
// require('core-js/modules/es6.weak-map');
// require('core-js/modules/es6.weak-set');
// 暂时不用array-buffer
// require('core-js/modules/es6.typed.array-buffer');
// require('core-js/modules/es6.typed.data-view');
// require('core-js/modules/es6.typed.int8-array');
// require('core-js/modules/es6.typed.uint8-array');
// require('core-js/modules/es6.typed.uint8-clamped-array');
// require('core-js/modules/es6.typed.int16-array');
// require('core-js/modules/es6.typed.uint16-array');
// require('core-js/modules/es6.typed.int32-array');
// require('core-js/modules/es6.typed.uint32-array');
// require('core-js/modules/es6.typed.float32-array');
// require('core-js/modules/es6.typed.float64-array');

// require('core-js/modules/es6.reflect.apply');
// require('core-js/modules/es6.reflect.construct');
// require('core-js/modules/es6.reflect.define-property');
// require('core-js/modules/es6.reflect.delete-property');
// require('core-js/modules/es6.reflect.enumerate');
// require('core-js/modules/es6.reflect.get');
// require('core-js/modules/es6.reflect.get-own-property-descriptor');
// require('core-js/modules/es6.reflect.get-prototype-of');
// require('core-js/modules/es6.reflect.has');
// require('core-js/modules/es6.reflect.is-extensible');
// require('core-js/modules/es6.reflect.own-keys');
// require('core-js/modules/es6.reflect.prevent-extensions');
// require('core-js/modules/es6.reflect.set');
// require('core-js/modules/es6.reflect.set-prototype-of');

// es7 修复
require('core-js/modules/es7.array.includes');
// require('core-js/modules/es7.string.at');
// require('core-js/modules/es7.string.pad-start');
// require('core-js/modules/es7.string.pad-end');
// require('core-js/modules/es7.string.trim-left');
// require('core-js/modules/es7.string.trim-right');
// require('core-js/modules/es7.string.match-all');

// require('core-js/modules/es7.symbol.async-iterator');
// require('core-js/modules/es7.symbol.observable');
require('core-js/modules/es7.object.get-own-property-descriptors');
require('core-js/modules/es7.object.values');
require('core-js/modules/es7.object.entries');
require('core-js/modules/es7.object.define-getter');
require('core-js/modules/es7.object.define-setter');
require('core-js/modules/es7.object.lookup-getter');
require('core-js/modules/es7.object.lookup-setter');
// require('core-js/modules/es7.map.to-json');
// require('core-js/modules/es7.set.to-json');
require('core-js/modules/es7.system.global');
require('core-js/modules/es7.error.is-error');
// require('core-js/modules/es7.math.iaddh');
// require('core-js/modules/es7.math.isubh');
// require('core-js/modules/es7.math.imulh');
// require('core-js/modules/es7.math.umulh');
// require('core-js/modules/es7.reflect.define-metadata');
// require('core-js/modules/es7.reflect.delete-metadata');
// require('core-js/modules/es7.reflect.get-metadata');
// require('core-js/modules/es7.reflect.get-metadata-keys');
// require('core-js/modules/es7.reflect.get-own-metadata');
// require('core-js/modules/es7.reflect.get-own-metadata-keys');
// require('core-js/modules/es7.reflect.has-metadata');
// require('core-js/modules/es7.reflect.has-own-metadata');
// require('core-js/modules/es7.reflect.metadata');
// 立即执行模块
// require('core-js/modules/es7.asap');
// require('core-js/modules/es7.observable');

require('core-js/modules/web.timers');
require('core-js/modules/web.immediate');
require('core-js/modules/web.dom.iterable');

// 如果要用 生成器或者async则需要加载这2个模块
require('regenerator-runtime/runtime');
require('core-js/fn/regexp/escape');

if (global._babelPolyfill) {
	throw new Error('only one instance of babel-polyfill is allowed');
}
global._babelPolyfill = true;

var DEFINE_PROPERTY = 'defineProperty';
function define(O, key, value) {
	O[key] || Object[DEFINE_PROPERTY](O, key, {
		writable: true,
		configurable: true,
		value: value
	});
}

define(String.prototype, 'padLeft', ''.padStart);
define(String.prototype, 'padRight', ''.padEnd);

'pop,reverse,shift,keys,values,entries,indexOf,every,some,forEach,map,filter,find,findIndex,includes,join,slice,concat,push,splice,unshift,sort,lastIndexOf,reduce,reduceRight,copyWithin,fill'.split(',').forEach(function (key) {
	[][key] && define(Array, key, Function.call.bind([][key]));
});
module.exports = require('core-js/modules/_core');

/*eslint-enable */
